exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-components-about-cpr-js": () => import("./../../../src/pages/components/about/Cpr.js" /* webpackChunkName: "component---src-pages-components-about-cpr-js" */),
  "component---src-pages-components-about-josie-js": () => import("./../../../src/pages/components/about/Josie.js" /* webpackChunkName: "component---src-pages-components-about-josie-js" */),
  "component---src-pages-components-about-salon-js": () => import("./../../../src/pages/components/about/Salon.js" /* webpackChunkName: "component---src-pages-components-about-salon-js" */),
  "component---src-pages-components-contact-contact-details-js": () => import("./../../../src/pages/components/contact/ContactDetails.js" /* webpackChunkName: "component---src-pages-components-contact-contact-details-js" */),
  "component---src-pages-components-contact-form-js": () => import("./../../../src/pages/components/contact/Form.js" /* webpackChunkName: "component---src-pages-components-contact-form-js" */),
  "component---src-pages-components-contact-map-js": () => import("./../../../src/pages/components/contact/Map.js" /* webpackChunkName: "component---src-pages-components-contact-map-js" */),
  "component---src-pages-components-contact-salon-gallery-js": () => import("./../../../src/pages/components/contact/SalonGallery.js" /* webpackChunkName: "component---src-pages-components-contact-salon-gallery-js" */),
  "component---src-pages-components-faqs-contact-us-cta-js": () => import("./../../../src/pages/components/faqs/ContactUsCta.js" /* webpackChunkName: "component---src-pages-components-faqs-contact-us-cta-js" */),
  "component---src-pages-components-faqs-questions-js": () => import("./../../../src/pages/components/faqs/Questions.js" /* webpackChunkName: "component---src-pages-components-faqs-questions-js" */),
  "component---src-pages-components-home-booking-cta-js": () => import("./../../../src/pages/components/home/BookingCta.js" /* webpackChunkName: "component---src-pages-components-home-booking-cta-js" */),
  "component---src-pages-components-home-hero-js": () => import("./../../../src/pages/components/home/Hero.js" /* webpackChunkName: "component---src-pages-components-home-hero-js" */),
  "component---src-pages-components-home-pamper-hero-js": () => import("./../../../src/pages/components/home/PamperHero.js" /* webpackChunkName: "component---src-pages-components-home-pamper-hero-js" */),
  "component---src-pages-components-home-services-js": () => import("./../../../src/pages/components/home/Services.js" /* webpackChunkName: "component---src-pages-components-home-services-js" */),
  "component---src-pages-components-home-welcome-js": () => import("./../../../src/pages/components/home/Welcome.js" /* webpackChunkName: "component---src-pages-components-home-welcome-js" */),
  "component---src-pages-components-services-blow-dry-js": () => import("./../../../src/pages/components/services/BlowDry.js" /* webpackChunkName: "component---src-pages-components-services-blow-dry-js" */),
  "component---src-pages-components-services-colour-js": () => import("./../../../src/pages/components/services/Colour.js" /* webpackChunkName: "component---src-pages-components-services-colour-js" */),
  "component---src-pages-components-services-free-consultation-cta-js": () => import("./../../../src/pages/components/services/FreeConsultationCta.js" /* webpackChunkName: "component---src-pages-components-services-free-consultation-cta-js" */),
  "component---src-pages-components-services-hair-guide-js": () => import("./../../../src/pages/components/services/HairGuide.js" /* webpackChunkName: "component---src-pages-components-services-hair-guide-js" */),
  "component---src-pages-components-services-style-cut-blow-dry-js": () => import("./../../../src/pages/components/services/StyleCutBlowDry.js" /* webpackChunkName: "component---src-pages-components-services-style-cut-blow-dry-js" */),
  "component---src-pages-components-services-treatment-js": () => import("./../../../src/pages/components/services/Treatment.js" /* webpackChunkName: "component---src-pages-components-services-treatment-js" */),
  "component---src-pages-components-shared-customer-testimonials-carousel-js": () => import("./../../../src/pages/components/shared/CustomerTestimonialsCarousel.js" /* webpackChunkName: "component---src-pages-components-shared-customer-testimonials-carousel-js" */),
  "component---src-pages-components-shared-footer-js": () => import("./../../../src/pages/components/shared/Footer.js" /* webpackChunkName: "component---src-pages-components-shared-footer-js" */),
  "component---src-pages-components-shared-nav-bar-js": () => import("./../../../src/pages/components/shared/NavBar.js" /* webpackChunkName: "component---src-pages-components-shared-nav-bar-js" */),
  "component---src-pages-components-shared-testimonial-js": () => import("./../../../src/pages/components/shared/Testimonial.js" /* webpackChunkName: "component---src-pages-components-shared-testimonial-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

